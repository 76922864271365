import {
  Box,
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { JsMenu } from "./api/data";
import React from "react";

export const JsHistory = () => {
  return (
    <HStack
      w={"100%"}
      h={"calc(100vh)"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      background={"linear-gradient(to top, #274046, #E6DADA)"}
      color={"#000000"}
    >
      <Box
        w={"50%"}
        h={"80vh"}
        bg={"#FFFFFF"}
        borderRadius={8}
        border={"3px solid gray"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Tabs isFitted variant={"enclosed"} size={"xl"} p={5}>
          <TabList>
            {JsMenu.map((item, index) => (
              <Tab key={index} p={4}>
                <Heading as={"h2"}>{item.title}</Heading>
              </Tab>
            ))}
          </TabList>
          <Box p={10}>
            <TabPanels overflowY={"scroll"} maxH={"70vh"}>
              {JsMenu.map((item, index) => (
                <TabPanel p={4} key={index}>
                  {item.content}
                </TabPanel>
              ))}
            </TabPanels>
          </Box>
        </Tabs>
      </Box>
    </HStack>
  );
};
