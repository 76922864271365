import React from "react";
import styled from "styled-components";

type SectionProps = {
  sectionId: string;
  children: React.ReactNode;
};

export const Section = ({ sectionId, children }: SectionProps) => {
  return <StyledSection id={sectionId}>{children}</StyledSection>;
};

const StyledSection = styled.section`
  width: 100%;
  min-height: calc(100vh);
`;
