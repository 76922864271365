import { BigButton } from "./components/BigButton";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { contacts } from "../../config/site";
import React from "react";

export const Contact = () => {
  return (
    <VStack
      id="contact"
      mt={"20rem"}
      width={"100%"}
      flexDirection={"column"}
      spacing={20}
    >
      <Box textAlign={"center"}>
        <Heading as={"h2"} fontSize={"4rem"} mb={"5rem"}>
          Contact
        </Heading>
        <Text fontWeight={"500"}>
          If you are interested in cooperation, do not hesitate to contact me by
          email, message or call, or contact form below.
        </Text>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"2rem"}
        placeItems={"center"}
        mt={"8rem"}
      >
        <BigButton type={"email"} text={contacts.email} />
        <BigButton type={"phone"} text={contacts.phone} />
      </Box>
    </VStack>
  );
};
