import { site } from "../config/site";
import javascriptLogo from "../assets/images/js-icon.svg";
import nodeLogo from "../assets/images/node-icon.svg";
import reactLogo from "../assets/images/react-icon.svg";
import styledComponentsLogo from "../assets/images/styledComponents-icon.png";
import typescriptLogo from "../assets/images/typescript-icon.svg";

type TechStack = {
  name: string;
  url?: string;
  icon?: string;
};

type AppProps = {
  id: number;
  url: string;
  name: string;
  imageFile?: string;
  imageAlt?: string;
  description?: string;
  techStack: TechStack[];
  externalUrl?: string;
  gitHubUrl?: string;
};

const appList: AppProps[] = [
  {
    id: 1,
    url: site.apps.jsHistory.link,
    name: site.apps.jsHistory.title,
    description:
      "Article about JavaScript History, react basics (components, map function, etc)",
    techStack: [
      { name: "React", icon: reactLogo },
      { name: "JavaScript", icon: javascriptLogo },
    ],
  },
  {
    id: 2,
    url: site.apps.counterApp.link,
    name: site.apps.counterApp.title,
    gitHubUrl: site.apps.counterApp.githubLink,
    description:
      "Simple counter application with react and typescript, used styled-components and react-icons.",
    techStack: [
      { name: "React", icon: reactLogo },
      { name: "TypeScript", icon: typescriptLogo },
      { name: "Styled-Components", icon: styledComponentsLogo },
    ],
  },
  {
    id: 3,
    url: site.apps.toDoApp.link,
    name: site.apps.toDoApp.title,
    description: "There will be description of application",
    techStack: [
      { name: "React", icon: reactLogo },
      { name: "TypeScript", icon: typescriptLogo },
      { name: "Styled-Components", icon: styledComponentsLogo },
    ],
  },
  {
    id: 4,
    url: site.apps.hackerTyperApp.link,
    name: site.apps.hackerTyperApp.title,
    description: "There will be description of application",
    techStack: [
      { name: "React", icon: reactLogo },
      { name: "TypeScript", icon: typescriptLogo },
      { name: "Styled-Components", icon: styledComponentsLogo },
    ],
  },
  {
    id: 5,
    url: site.apps.roboFriends.link,
    name: site.apps.roboFriends.title,
    description: "There will be description of application",
    techStack: [
      { name: "React", icon: reactLogo },
      { name: "JavaScript", icon: javascriptLogo },
    ],
    externalUrl: site.apps.roboFriends.githubLink,
  },
  {
    id: 6,
    url: site.apps.smartBrain.link,
    name: site.apps.smartBrain.title,
    description: "There will be description of application",
    techStack: [
      { name: "React", icon: reactLogo },
      { name: "Node.js", icon: nodeLogo },
    ],
    externalUrl: site.apps.smartBrain.githubLink,
  },
  {
    id: 7,
    url: site.apps.ticTacToeGame.link,
    name: site.apps.ticTacToeGame.title,
    description: "Basic tic tac toe game with react and typescript.",
    techStack: [
      { name: "React", icon: reactLogo },
      { name: "TypeScript", icon: typescriptLogo },
      { name: "Styled-Components", icon: styledComponentsLogo },
    ],
    gitHubUrl: site.apps.ticTacToeGame.githubLink,
  },
];

export function getApps() {
  return appList;
}
