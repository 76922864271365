import {
  Box,
  HStack,
  Image,
  Link,
  Text,
  keyframes,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "./components/Logo";
import { SocialIconsList } from "./components/SocialIconsList";
import { site } from "../../config/site";
import React from "react";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export function Footer() {
  const bgColor = useColorModeValue("gray.100", "gray.900");

  const animationRotate = `${rotate} 5s linear infinite`;

  return (
    <HStack
      p={"3rem 15rem"}
      mt={"15rem"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      bg={bgColor}
    >
      <Box>
        <Logo />
      </Box>
      <Box
        letterSpacing={"0.2rem"}
        display={"flex"}
        alignItems={"center"}
        gap={"0.5rem"}
      >
        <Text>Created by </Text>
        <Link href={site.externalUrl.react.link}>
          <Image
            src={site.externalUrl.react.icon}
            alt={site.externalUrl.react.title}
            w={"2.6rem"}
            animation={animationRotate}
          />
        </Link>
        |
        <Link href={site.externalUrl.styledComponents.link}>
          <Image
            src={site.externalUrl.styledComponents.icon}
            alt={site.externalUrl.styledComponents.title}
            w={"2.6rem"}
          />
        </Link>
        |
        <Link href={site.externalUrl.chakraUi.link}>
          <Image
            src={site.externalUrl.chakraUi.icon}
            alt={site.externalUrl.chakraUi.title}
            w={"2.6rem"}
          />
        </Link>
      </Box>

      <SocialIconsList />
    </HStack>
  );
}
