import { Box, Link, Text } from "@chakra-ui/react";
import { site } from "../../../config/site";
import React from "react";
export const Logo = () => {
  return (
    <Box pl={20}>
      <Link href={site.initialPage.link}>
        <Text as="b" fontSize={"5xl"}>
          {site.website.title}
        </Text>
      </Link>
    </Box>
  );
};
