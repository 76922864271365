import { Carrier, Customer, Education, Job } from "./dataDef";
import androidIcon from "../../../assets/images/android_icon.png";
import angularIcon from "../../../assets/images/angular_icon.png";
import awsIcon from "../../../assets/images/aws_icon.png";
import cIcon from "../../../assets/images/c_icon.png";
import carvagoLogo from "../../../assets/images/carvago_logo.svg";
import dotnetIcon from "../../../assets/images/dotnet_icon.png";
import githubIcon from "../../../assets/images/github.png";
import gitlabIcon from "../../../assets/images/gitlab_icon.png";
import jiraIcon from "../../../assets/images/jira_icon.png";
import kvadosLogo from "../../../assets/images/kvados_logo.png";
import mssqlIcon from "../../../assets/images/mssql_icon.png";
import oracleIcon from "../../../assets/images/oracle_icon.png";
import phpIcon from "../../../assets/images/php_icon.png";
import postgresqlIcon from "../../../assets/images/postgresql_icon.png";
import reactIcon from "../../../assets/images/react-icon.svg";
import tietoevryLogo from "../../../assets/images/tietoevry_logo.svg";
import typescriptIcon from "../../../assets/images/typescript-icon.svg";
import vscodeIcon from "../../../assets/images/vscode-icon.svg";
import vstsIcon from "../../../assets/images/vsts_icon.png";
import webstormIcon from "../../../assets/images/webstorm-icon.png";

export const EducationAndTraining: Education[] = [
  {
    date: "2006",
    cert: "Degree obtained",
    studies: "Electro Engineering, Electronics and Computer science",
    institution: "Technical college-State school specialises",
    type: "school",
  },
  {
    date: "2022",
    cert: "UC-4ed7de9e-c13b-4443-afee-3e46c6a36267",
    studies: "The Complete Web Developer in 2022: Zero to Mastery",
    institution: "Udemy",
    type: "training",
    url: "http://abcd.com",
  },
  {
    date: "2022",
    cert: "UC-d6eaaf31-44ea-4c61-b899-983a3dabf244",
    studies: "Sketch from A to Z (2022): Become an app designer",
    institution: "Udemy",
    type: "training",
  },
  {
    date: "2022",
    cert: "UC-25d25aef-2f1d-44b7-9d32-06925677957b",
    studies: "The Complete 2022 Web Development Bootcamp",
    institution: "Udemy",
    type: "training",
  },
  {
    date: "2013",
    cert: "License P2R/965194",
    studies: "PRINCE2 Foundation",
    institution: "APMG-International",
    type: "training",
  },
  {
    date: "2018",
    cert: "Certification obtained",
    studies: "Certified Scrum Product Owner",
    institution: "Scrum Alliance",
    type: "training",
  },
  {
    date: "2009",
    cert: "Certification obtained",
    studies: "ITIL Foundation",
    institution: "APMG-International",
    type: "training",
  },
  {
    date: "2009",
    cert: "Certification obtained",
    studies: "ITIL Service Manger",
    institution: "APMG-International",
    type: "training",
  },
];

export const WorkExperience: Carrier[] = [
  {
    dates: "2013 - present",
    employer: { name: "Freelancer" },
    businessType: "Various industries",
    position: "Frontend developer",
    techStack: [
      { name: "React", icon: reactIcon },
      { name: "Typescript", icon: typescriptIcon },
      { name: "Github", icon: githubIcon },
      { name: "Webstorm", icon: webstormIcon },
    ],
  },
  {
    dates: "2022 - present",
    employer: {
      name: "Carvago, s.r.o.",
      logo: carvagoLogo,
      url: "http://carvago.com",
    },
    employerLogo: carvagoLogo,
    position: "Delivery manager, Deploy & release process",
    businessType: "Automotive",
    responsibilities:
      "Responsible for deploy and release process SaaS\n" +
      "application, quality assurance of the application",
    techStack: [
      { name: "React", icon: reactIcon },
      { name: "Typescript", icon: typescriptIcon },
      { name: "Php", icon: phpIcon },
      { name: "Gitlab", icon: gitlabIcon },
      { name: "PostgreSQL", icon: postgresqlIcon },
      { name: "Jira", icon: jiraIcon },
      { name: "AWS", icon: awsIcon },
    ],
  },
  {
    dates: "2020 - 2022",
    employer: {
      name: "Carvago, s.r.o.",
      logo: carvagoLogo,
      url: "http://carvago.com",
    },
    employerLogo: carvagoLogo,
    position: "Product Owner",
    businessType: "Automotive",
    techStack: [
      { name: "React", icon: reactIcon },
      { name: "Typescript", icon: typescriptIcon },
      { name: "Php", icon: phpIcon },
      { name: "Gitlab", icon: gitlabIcon },
      { name: "PostgreSQL", icon: postgresqlIcon },
      { name: "Jira", icon: jiraIcon },
      { name: "AWS", icon: awsIcon },
    ],
  },
  {
    dates: "2018 - 2020",
    employer: {
      name: "Tieto Czech s.r.o.",
      logo: tietoevryLogo,
      url: "https://www.tietoevry.com/cz/",
    },
    employerLogo: tietoevryLogo,
    position: "Product Owner, Senior System Analyst",
    businessType: "Utilities",
    responsibilities:
      "Product owner of the application for the management of the distribution network",
    techStack: [
      { name: "Angular", icon: angularIcon },
      { name: "Oracle", icon: oracleIcon },
      { name: "C", icon: cIcon },
      { name: "Visual Studio Team Server", icon: vstsIcon },
      { name: "Visual Studio Code", icon: vscodeIcon },
    ],
  },
  {
    dates: "2016 - 2027",
    employer: {
      name: "KVADOS, a.s.",
      logo: kvadosLogo,
      url: "https://kvados.cz",
    },
    employerLogo: kvadosLogo,
    position: "Product Owner",
    responsibilities:
      "Responsible for the development of the application on Android platform, myAvis new generation application",
    businessType: "Various industries",
    techStack: [
      { name: "Android", icon: androidIcon },
      { name: "MS SQL", icon: mssqlIcon },
      { name: "C", icon: cIcon },
      { name: ".NET", icon: dotnetIcon },
    ],
  },
  {
    dates: "2011 - 2016",
    employer: {
      name: "KVADOS, a.s.",
      logo: kvadosLogo,
      url: "https://kvados.cz",
    },
    employerLogo: kvadosLogo,
    position: "Project manager",
    businessType: "Utilities",
    techStack: [
      { name: "MS SQL", icon: mssqlIcon },
      { name: "C", icon: cIcon },
      { name: ".NET", icon: dotnetIcon },
    ],
  },
  {
    dates: "2008 - 2011",
    employer: {
      name: "KVADOS, a.s.",
      logo: kvadosLogo,
      url: "https://kvados.cz",
    },
    employerLogo: kvadosLogo,
    position: "Solution consultant, Change manager",
    businessType: "Utilities",
    techStack: [
      { name: "MS SQL", icon: mssqlIcon },
      { name: "C", icon: cIcon },
      { name: ".NET", icon: dotnetIcon },
    ],
  },
];

export const Customers: Customer[] = [];

export const JobTitles: Job[] = [
  { name: "Product Owner" },
  { name: "Problem Solver" },
  { name: "Idea Maker" },
  { name: "Frontend Developer" },
];
