import {
  Badge,
  Box,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { getApps } from "../../api/appList";
import { getSocials, subdomainLink } from "../../config/site";
import externalLinkIcon from "../../assets/images/external-link-icon.svg";
import folderIcon from "../../assets/images/folder.svg";
import githubIcon from "../../assets/images/github-icon.svg";
import React from "react";

export const Portfolio = () => {
  const projects = getApps();
  const gitHubAccount = getSocials().find((item) => item.title === "GitHub");

  return (
    <HStack mt={"15rem"} id="portfolio" h={"100vh"}>
      <Box display={"flex"} flexDirection={"column"} w={"100%"}>
        <Heading as={"h2"} textAlign={"center"} fontSize={"4rem"} mb={"5rem"}>
          App Playground
        </Heading>
        <Box
          display={"flex"}
          justifyContent={"center"}
          w={"100%"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid
            templateColumns={"repeat(4, 1fr)"}
            templateRows={"auto"}
            gap={"2rem"}
            p={"1rem"}
            overflow={"hidden"}
          >
            {projects.map((project, index) => {
              return (
                <GridItem
                  key={index}
                  p={"2rem 1.8rem"}
                  bg={useColorModeValue("gray.100", "gray.600")}
                  borderRadius={"1.2rem"}
                  transition={"0.25s"}
                  display={"flex"}
                  flexDirection={"column"}
                  h={"100%"}
                  _hover={{
                    transform: "translateY(-5px)",
                    bg: useColorModeValue("gray.400", "gray.500"),
                  }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    color={"#016fb9"}
                    mb={"3.6rem"}
                  >
                    <Image src={folderIcon} alt="folder" />
                    <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
                      {project.url ? (
                        <Link
                          href={subdomainLink(project.url)}
                          target="_blank"
                          rel="noreferrer"
                          isExternal
                        >
                          <Image
                            w={"2.6rem"}
                            src={externalLinkIcon}
                            fill={"#61DBFB"}
                            alt={"Zobrazit aplikaci"}
                          />
                        </Link>
                      ) : null}
                      {project.gitHubUrl ? (
                        <Link
                          href={gitHubAccount?.url + project.gitHubUrl}
                          target="_blank"
                          rel="noreferrer"
                          isExternal
                        >
                          <Image
                            w={"2.6rem"}
                            src={githubIcon}
                            alt={"Zobrazit GitHub"}
                          />
                        </Link>
                      ) : null}
                    </Box>
                  </Box>
                  <Box>
                    <Heading as={"h3"} mb={"2rem"}>
                      {project.name}
                    </Heading>
                    <Text letterSpacing={"0.12rem"} mb={"2rem"}>
                      {project.description}
                    </Text>
                  </Box>
                  <Box
                    mt={"auto"}
                    display={"flex"}
                    alignItems={"baseline"}
                    gap={4}
                  >
                    {project.techStack?.map((item, index) => {
                      return (
                        <div key={index}>
                          {item.icon ? (
                            <Image
                              src={item.icon}
                              alt={item.name}
                              h={10}
                              w={"auto"}
                            />
                          ) : (
                            <Badge
                              borderRadius="full"
                              px="4"
                              colorScheme="teal"
                              key={index}
                            >
                              {item.name}
                            </Badge>
                          )}
                        </div>
                      );
                    })}
                  </Box>
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </HStack>
  );
};
