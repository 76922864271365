import { App } from "./routes/App/App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { GlobalStyle } from "./GlobalStyles";
import { JsHistory } from "./routes/JsHistory/JsHistory";
import { ToDoApp } from "./routes/ToDoApp/ToDoApp";
import { site } from "./config/site";
import { theme } from "./theme";
import React from "react";
import ReactDOM from "react-dom/client";
import styled, { ThemeProvider } from "styled-components";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const Main_styled = styled.main`
  padding: 1rem;
`;

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path={site.initialPage.link} element={<App />} />
          <Route path={site.apps.jsHistory.link} element={<JsHistory />} />
          <Route path={site.apps.toDoApp.link} element={<ToDoApp />} />
          <Route
            path={site.pageNotFound.link}
            element={
              <Main_styled>
                <p>There`&apos;`s nothing here!</p>
              </Main_styled>
            }
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </ThemeProvider>,
  // </React.StrictMode>
);
