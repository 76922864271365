import { Box } from "@chakra-ui/react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Main } from "./Main";
import React from "react";

export const App = () => {
  return (
    <>
      <Header />
      <Box overflowY={"scroll"} scrollBehavior={"smooth"}>
        <Main />
      </Box>
      <Footer />
    </>
  );
};
