import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Heading,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { JobTitles } from "./utils/data";
import { Section } from "./components/Section";
import { SocialIconsList } from "./components/SocialIconsList";
import { VDivider } from "./components/VDivider";
import { getMenuItems, site } from "../../config/site";
import { theme } from "../../theme";
import React from "react";

export const Hero = () => {
  const bgZoneColor = useColorModeValue("gray.100", "gray.600");
  const contactLink = getMenuItems().find((item) => item.id === 40);
  const resumeLink: string = site.website.resume;
  const bgButtonColor = useColorModeValue("gray.100", "whiteAlpha.900");

  return (
    <Section sectionId={"home"}>
      <HStack
        display={"flex"}
        justifyContent={"center"}
        gap={"8rem"}
        h={"100vh"}
        w={"100%"}
        flexDirection={"column"}
      >
        <Box
          fontSize={"1.8rem"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          bg={bgZoneColor}
          p={"20px"}
          borderRadius={10}
        >
          <Text>Hello, I&apos;m</Text>
          <Heading as={"h1"} fontSize={"7rem"}>
            Vojtěch Beneš
          </Heading>
          <Heading as={"h3"} m={"1rem 0"} color={theme.colors.reactBlue}>
            <Box display={"flex"} flexDirection={"row"}>
              {JobTitles.map((job, index) => (
                <Box display={"flex"} flexDirection={"row"} key={index}>
                  {job.name}{" "}
                  {index === JobTitles.length - 1 ? null : <VDivider />}
                </Box>
              ))}
            </Box>
          </Heading>
          <Text mb={"5rem"}>
            who has an experience from application development and system
            implementation in various industries, customers or environments.
          </Text>
          <Box mb={10}>
            <SocialIconsList />
          </Box>
          <Box>
            <ButtonGroup gap={4}>
              {resumeLink !== null && resumeLink !== "" ? (
                <Button
                  size={"xl"}
                  p={4}
                  variant={"solid"}
                  colorScheme={"teal"}
                  rightIcon={<FiDownload />}
                >
                  <Link href={resumeLink} style={{ textDecoration: "none" }}>
                    Resume
                  </Link>
                </Button>
              ) : null}

              <Button
                size={"xl"}
                p={4}
                variant={"outline"}
                colorScheme={"teal"}
              >
                <Link
                  href={contactLink?.url}
                  style={{ textDecoration: "none" }}
                >
                  Contact
                </Link>
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
          bottom={6}
        >
          <Link
            href={"#aboutme"}
            bg={bgButtonColor}
            borderRadius={100}
            p={6}
            color={"blackAlpha.900"}
            _focus={{ border: "1px solid #ffffff" }}
          >
            <FaChevronDown />
          </Link>
        </Box>
      </HStack>
    </Section>
  );
};
