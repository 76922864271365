import { Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export const VDivider = () => {
  const dividerColor = useColorModeValue("#000000", "#FFFFFF");

  return (
    <Text color={dividerColor} pl={"5"} pr={"5"} fontWeight={"100"}>
      |
    </Text>
  );
};
