import {
  Box,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Carrier } from "../utils/dataDef";
import React from "react";

export const CarrierZone = ({
  dates,
  position,
  responsibilities,
  employer,
  businessType,
  techStack,
}: Carrier) => {
  const bgLogoColor = useColorModeValue(`brightness(1)`, `brightness(1.80)`);

  return (
    <>
      <Box mt={8} display={"flex"} justifyContent={"flex-start"}>
        <Box
          w={"10vh"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={4}
          mr={10}
          ml={10}
        >
          {employer.logo ? (
            <Image
              src={employer.logo}
              alt={"employer logo"}
              filter={bgLogoColor}
            />
          ) : null}
          <Heading size={"md"}>{dates}</Heading>
        </Box>
        <VStack alignItems={"flex-start"}>
          <Text fontSize={"xxl"}>{position}</Text>
          <Text fontSize={"lg"}>{responsibilities}</Text>
          <Text fontSize={"md"}>
            {employer.url ? (
              <Link href={employer.url} isExternal target={"_blank"}>
                {employer.name}
              </Link>
            ) : (
              employer.name
            )}
            {businessType ? " | " + businessType : null}
          </Text>
          <Box display={"flex"} gap={"2"}>
            {!techStack
              ? null
              : techStack.map((item, index) => (
                  <Image
                    key={index}
                    src={item.icon}
                    alt={item.name}
                    h={8}
                    w={"auto"}
                    filter={"grayscale(1)"}
                    transition={"filter 0.5s"}
                    _hover={{
                      filter: "grayscale(0)",
                    }}
                  />
                ))}
          </Box>
        </VStack>
      </Box>
    </>
  );
};
