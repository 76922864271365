import { useState } from "react";

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // eslint-disable-next-line no-undef
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch {
      // eslint-disable-next-line no-undef
      console.error;
      return initialValue;
    }
  });

  // eslint-disable-next-line no-unused-vars
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      // eslint-disable-next-line no-undef
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch {
      // eslint-disable-next-line no-undef
      console.error;
    }
  };
  return [storedValue, setValue] as const;
};
