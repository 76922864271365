import { Box, Image, Link } from "@chakra-ui/react";
import { theme } from "../../../theme";
import emailIcon from "../../../assets/images/email-icon.svg";
import phoneIcon from "../../../assets/images/phone-icon.svg";
import React from "react";

type BigButtonProps = {
  type: "phone" | "email";
  text: string;
  email?: string;
  phoneNumber?: string;
};

export const BigButton = (props: BigButtonProps) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      w={"50%"}
      maxWidth={"40rem"}
      gap={"2rem"}
      bg={theme.colors.reactBlue}
      borderRadius={"1.4rem"}
      padding={"1.6rem 2.8rem"}
      transition={{
        backgroundColor: "0.25s",
      }}
      _hover={{
        bg: "#E31F71",
        color: "#fff",
      }}
    >
      <Image
        src={props.type === "email" ? emailIcon : phoneIcon}
        alt={props.type}
        w={"4rem"}
      />
      <Link
        href={
          props.type === "email" ? "mailto:" + props.text : "tel:" + props.text
        }
        color={"#212121"}
        fontWeight={"500"}
        _hover={{
          textDecoration: "none",
        }}
      >
        {props.text}
      </Link>
    </Box>
  );
};
