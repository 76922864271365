import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
  :root {
    --pink: ${theme.colors.pink};
    --black: ${theme.colors.black};
    --green: ${theme.colors.green};
    --blue: ${theme.colors.blue};
    --reactblue: ${theme.colors.reactBlue};
    scroll-padding-top: 10rem;
    
    // TODO: refactor to separate theme
    &.light{

      body{
        transition: 0.5s;
        background-color: #f5f5f5;
        color: var(--black);
      }

      header.header-fixed{
        transition: 0.5s;
        background-color: #f5f5f550;
        a{
          transition: 0.5s;
          color: black;
        }
        .menu,.menu:before, .menu:after{
          background-color: var(--black); 
        }
        .menu.active{
          background-color: rgba(555,555,555,0);
        }
      }

      footer.footer{
        transition: 0.5s;
        background-color: rgba(0,0,0,0.1);
      }
      footer p{
        color: var(--reactblue)
      }

      //form{
      //  input,textarea{
      //    transition: 0.5s;
      //    border: solid 1px var(--black);
      //    color: var(--black);
      //    &::placeholder{
      //      transition: 0.5s;
      //      color: var(--black);
      //    }
      //  }
      //}

    }
  }



  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 62.5%;
  }

  body{
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
  }

  body, input, textarea, button{
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
  }

  a{
    text-decoration: none;
  }
`;
