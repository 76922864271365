import { Box, Image, Link } from "@chakra-ui/react";
import { Skill } from "../utils/dataDef";
import React from "react";

export const Ability = (props: Skill) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      filter={"grayscale(1)"}
      transition={"filter 0.5s"}
      _hover={{
        filter: "grayscale(0)",
      }}
    >
      <Link
        href={props.url}
        title={props.description}
        target="_blank"
        rel="noreferrer"
      >
        <Image src={props.icon} alt={props.description} w={"3.4rem"} />
      </Link>
    </Box>
  );
};
