import { ContactInfo, Social } from "../types";
import {
  RxDiscordLogo,
  RxGithubLogo,
  RxInstagramLogo,
  RxLinkedinLogo,
  RxTwitterLogo,
} from "react-icons/rx";
import chakraUiIcon from "../assets/images/chakraui-icon.svg";
import discordIcon from "../assets/images/discord.png";
import githubIcon from "../assets/images/github.png";
import imageOfMe from "../assets/images/imageOfMe.jpg";
import instagramIcon from "../assets/images/instagram.png";
import linkedinIcon from "../assets/images/linkedin.png";
import reactIcon from "../assets/images/react-icon.svg";
import styledComponentIcon from "../assets/images/styledComponents-icon.svg";

export const contacts: ContactInfo = {
  email: "info@vojtechbenes.cz",
  phone: "+420774251345",
};

export const socialLinks: Social[] = [
  { label: "GitHub", img: RxGithubLogo, href: "https://github.com/benesvoj" },
  {
    label: "Discord",
    img: RxDiscordLogo,
    href: "https://discord.com/users/vojtabe#0966",
  },
  {
    label: "LinkedIn",
    img: RxLinkedinLogo,
    href: "https://www.linkedin.com/in/benesvojtech/",
  },
  {
    label: "Instagram",
    img: RxInstagramLogo,
    href: "https://www.instagram.com/vojtechbenes/",
  },
  {
    label: "Twitter",
    img: RxTwitterLogo,
    href: "https://twitter.com/vojtechbenes",
  },
];

export const subdomainLink = (subdomain: string) =>
  `http://${subdomain}.vojtechbenes.cz`;

export const site = {
  website: {
    link: "https://vojtechbenes.cz",
    title: "vojTECH.benes",
    resume: "",
    reCaptcha: {
      siteKey: "6LcWzYEjAAAAAMC6q3vPHHKCjuXeHj6H5P8QYQNl",
      siteKeyTest: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    },
    personalAccounts: [
      {
        id: 1,
        title: "linkedin",
        icon: linkedinIcon,
        url: "https://www.linkedin.com/in/benesvojtech/",
      },
      {
        id: 2,
        title: "GitHub",
        icon: githubIcon,
        url: "https://github.com/benesvoj",
      },
      {
        id: 3,
        title: "Instagram",
        icon: instagramIcon,
        url: "https://www.instagram.com/vojtechbenes/",
      },
      {
        id: 4,
        title: "Discord",
        icon: discordIcon,
        url: "https://discord.com/users/vojtabe#0966",
      },
    ],
    imageOfMe: imageOfMe,
    menuNavigation: [
      {
        id: 10,
        title: "Home",
        url: "#home",
        isAvailable: true,
      },
      {
        id: 30,
        title: "Apps playground",
        url: "#portfolio",
        isAvailable: true,
      },
      {
        id: 20,
        title: "About me",
        url: "#aboutme",
        isAvailable: true,
      },
      {
        id: 40,
        title: "Contact",
        url: "#contact",
        isAvailable: true,
      },
      {
        id: 50,
        title: "Projects",
        url: "#projects",
        isAvailable: false,
      },
    ],
  },
  apps: {
    jsHistory: {
      link: "js-history",
      title: "JavaScript history",
      pages: {
        home: {
          link: "#home",
          title: "Home",
        },
        flexbox: {
          link: "#robots",
          title: "Flexbox",
        },
        form: {
          link: "#subscribeForm",
          title: "Subscribe form",
        },
      },
    },
    counterApp: {
      link: "simplecounterapp",
      title: "Simple Counter App",
      githubLink: "/simplecounterapp",
    },
    toDoApp: {
      link: "todo-app",
      title: "ToDo app",
    },
    hackerTyperApp: {
      link: "hackerTyper-app",
      title: "HackerTyperApp",
    },
    roboFriends: {
      link: "https://benesvoj.github.io/robofriends/",
      title: "Robofriends",
      githubLink: "robofriends",
    },
    smartBrain: {
      link: "https://smart-brain.vojtechbenes.cz/",
      title: "Smart-brain",
      githubLink: "facerecognisationbrain",
    },
    ticTacToeGame: {
      link: "tictactoegame",
      title: "TicTacToe Game",
      githubLink: "/tictactoegame",
    },
  },
  pageNotFound: {
    link: "*",
    title: "Page not found",
  },
  initialPage: {
    link: "/",
    title: "home",
  },
  svgLink: "http://www.w3.org/2000/svg",
  externalUrl: {
    react: {
      link: "https://reactjs.org/",
      icon: reactIcon,
      title: "React",
    },
    styledComponents: {
      link: "https://styled-components.com/",
      icon: styledComponentIcon,
      title: "Styled Components",
    },
    chakraUi: {
      link: "https://chakra-ui.com/",
      icon: chakraUiIcon,
      title: "Chakra-UI",
    },
  },
};
// export const projects = {
//   jsHistory: {
//     link: 'js-history',
//     title: 'JavaScript history',
//   },
//   counterApp: {
//     link: 'counter-app',
//     title: 'Counter app',
//   },
//   toDoApp: {
//     link: 'todo-app',
//     title: 'ToDo app',
//   },
//   hackerTyperApp: {
//     link: 'hackerTyper-app',
//     title: 'HackerTyperApp',
//   },
//   roboFriends: {
//     link: 'https://benesvoj.github.io/robofriends/',
//     title: 'Robofriends',
//     githubLink: 'https://github.com/benesvoj/robofriends',
//   },
//   smartBrain: {
//     link: 'https://smart-brain.vojtechbenes.cz/',
//     title: 'Smart-brain',
//     githubLink: 'https://github.com/benesvoj/facerecognisationbrain',
//   },
// }

export const getMenuItems = () => site.website.menuNavigation;
export const getSocials = () => site.website.personalAccounts;
