import { Ability } from "./components/Ablity";
import {
  Box,
  HStack,
  Heading,
  Image,
  Img,
  Link,
  Text,
  keyframes,
} from "@chakra-ui/react";
import { CarrierZone } from "./components/CarrierZone";
import { Customers, EducationAndTraining, WorkExperience } from "./utils/data";
import { ResumeZone } from "./components/ResumeZone";
import { getSkills } from "./utils/skills";
import { site } from "../../config/site";
import React from "react";

const float = keyframes`
    0 % {
      box-shadow: 0 5px 15px 0 rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 25px 15px 0 rgba(0,0,0,0.2);
      transform: translatey(-20px);
    }
    100% {
      box-shadow: 0 5px 15px 0 rgba(0,0,0,0.6);
      transform: translatey(0px);
  }
`;

export const About = () => {
  const skills = getSkills();

  return (
    <HStack display={"flex"} flexDirection={"column"} id={"aboutme"}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Heading as={"h2"} fontSize={"4rem"} mb={"1rem"}>
          About me
        </Heading>
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box w={"60%"}>
          <Box>
            <Heading
              as={"h2"}
              mb={"1rem"}
              mt={"2rem"}
              borderBottom={"0.2rem solid #016fb9"}
            >
              Who am I?
            </Heading>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              h={"150px"}
              w={"150px"}
              borderRadius={"50%"}
              position={"relative"}
              overflow={"hidden"}
              mr={"2rem"}
              border={"0.5rem solid #016fb9"}
              boxShadow={"0 5px 15px 0px rgba(0,0,0,0.6)"}
              transform={`translatey(0px)`}
              animation={`${float} 6s ease-in-out infinite`}
            >
              <Image
                src={site.website.imageOfMe}
                alt="Fotografie"
                w={"100%"}
                h={"auto"}
              />
            </Box>
            <Box w={"70%"}>
              <Text>
                I am a very passionate and open-minded professional with an
                interest in technology and sports. During throughout my career I
                have worked on foreign and domestic projects, large ones even of
                smaller scopes, both from the point of view of costs and the
                content of individual projects.
              </Text>
              <Text>
                Despite all the differences, whether geographical, industry or
                peculiarities local market, I was always able to deliver a
                solution that satisfied the customer and brought him new
                opportunities in his business.
              </Text>
              <Text>I am not afraid of any new challenge.</Text>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          w={"100%"}
          gap={20}
        >
          <Box w={"50%"}>
            <Heading
              as={"h2"}
              mb={"1rem"}
              mt={"2rem"}
              borderBottom={"0.2rem solid #016fb9"}
            >
              Education & trainings
            </Heading>
            {EducationAndTraining.map((d, index) => {
              return (
                <ResumeZone
                  key={index}
                  date={d.date}
                  cert={d.cert}
                  studies={d.studies}
                  institution={d.institution}
                  type={d.type}
                  url={d.url}
                />
              );
            })}
          </Box>

          <Box w={"50%"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Heading
                as={"h2"}
                mb={"1rem"}
                mt={"2rem"}
                borderBottom={"0.2rem solid #016fb9"}
              >
                Work Experience
              </Heading>
              {WorkExperience.map((item, index) => {
                return (
                  <CarrierZone
                    key={index}
                    dates={item.dates}
                    position={item.position}
                    employer={item.employer}
                    employerLogo={item.employerLogo}
                    businessType={item.businessType}
                    techStack={item.techStack}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        display={"flex"}
        w={"100%"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Heading as={"h3"} mt={" 1rem"} color={"#61DBFB"}>
          Tech stack
        </Heading>
        <Box
          mt={"1.6rem"}
          display={"flex"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={"1.5rem"}
        >
          {skills.map((skill, index) => {
            return (
              <Ability
                key={index}
                title={skill.title}
                icon={skill.icon}
                description={skill.description}
                url={skill.url}
              />
            );
          })}
        </Box>
      </Box>
      {Customers.length !== 0 ? (
        <Box
          display={"flex"}
          w={"100%"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Heading as={"h3"} mt={" 1rem"} color={"#61DBFB"}>
            Customers
          </Heading>
          <Box
            display={"flex"}
            w={"100%"}
            gap={20}
            justifyContent={"center"}
            mt={8}
          >
            {Customers.map((item, index) => {
              return (
                <Link href={item.url} key={index}>
                  <Img src={item.logo} alt={item.name} h={"50px"} />
                </Link>
              );
            })}
          </Box>
        </Box>
      ) : null}
    </HStack>
  );
};
