import { Box, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { Education } from "../utils/dataDef";
import { LiaCertificateSolid } from "react-icons/lia";
import { TbSchool } from "react-icons/tb";
import React from "react";

export const ResumeZone = ({
  date,
  cert,
  studies,
  institution,
  type,
  url,
}: Education) => {
  return (
    <Box display={"flex"} mt={8}>
      {date ? (
        <Box
          w={"20%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          {type === "school" ? (
            <TbSchool size={36} />
          ) : (
            <LiaCertificateSolid size={28} />
          )}
          <Heading size={"md"}>{date}</Heading>
        </Box>
      ) : null}
      <VStack alignItems={"flex-start"}>
        <Text fontSize={"xxl"}>{studies}</Text>
        <Text fontSize={"lg"}>{institution}</Text>
        <Text fontSize={"md"}>
          {!url ? (
            <>{cert}</>
          ) : (
            <Link href={url} target={"_blank"}>
              {cert}
            </Link>
          )}
        </Text>
      </VStack>
    </Box>
  );
};
