import { Box, Icon, Link } from "@chakra-ui/react";
import { socialLinks } from "../../../config/site";
import React from "react";

export const SocialIconsList = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={"1rem"}
    >
      {socialLinks.map(({ label, img, href }) => {
        return (
          <Link
            key={label}
            href={href}
            target="_blank"
            aria-label={label}
            rel="noreferrer"
            p={"4px"}
          >
            <Icon
              as={img}
              w={"36px"}
              h={"36px"}
              p={"4px"}
              _hover={{
                border: "1px solid #61DBFB",
                borderRadius: "4px",
                color: "#61DBFB",
                p: "4px",
              }}
              border={"1px solid transparent"}
            />
          </Link>
        );
      })}
    </Box>
  );
};
