import {
  Box,
  HStack,
  IconButton,
  Link,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "./components/Logo";
import { MoonFilledIcon, SunFilledIcon } from "./components/Icons";
import { getMenuItems } from "../../config/site";
import React from "react";

export const Header = () => {
  // const [isEnabled, setEnabled] = useState(false);
  //
  // const closeMenu = () => {
  //   setEnabled(false);
  // };

  const menuItems = getMenuItems().sort((a, b) => a.id - b.id);

  const { toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const selectedColor = useColorModeValue(
    <MoonFilledIcon />,
    <SunFilledIcon />,
  );

  return (
    <HStack
      position={"fixed"}
      top={0}
      h={24}
      justifyContent={"space-between"}
      bg={bgColor}
      overflow={"hidden"}
      w={"100%"}
      zIndex={1000}
    >
      <Logo />

      <Box p={4}>
        <Stack direction={"row"} align={"center"}>
          <IconButton
            aria-label={"Color switch"}
            icon={selectedColor}
            onClick={toggleColorMode}
          />
          {menuItems.map((menuItem) => {
            return menuItem.isAvailable ? (
              <Link
                key={menuItem.id}
                href={menuItem.url}
                // onClick={closeMenu}
                p={4}
              >
                <Text fontSize={"3xl"} as={"b"}>
                  {menuItem.title}
                </Text>
              </Link>
            ) : null;
          })}
        </Stack>
      </Box>
    </HStack>
  );
};
