import { About } from "./About";
import { Contact } from "./Contact";
import { Hero } from "./Hero";
import { ParticlesContainer } from "./components/ParticlesContainer";
import { Portfolio } from "./Portfolio";
import { loadFull } from "tsparticles";
import React, { useCallback } from "react";
import styled from "styled-components";
import type { Engine } from "tsparticles-engine";

export const Main = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Main_Container>
      <ParticlesContainer init={particlesInit} />
      <Hero />
      <About />
      <Portfolio />
      <Contact />
    </Main_Container>
  );
};

const Main_Container = styled.main`
  position: relative;
  z-index: 0;
  padding: 0 10rem;
  overflow-x: hidden;

  ${(p) => p.theme.responsive.md} {
    padding: 0 4rem;
  }

  ${(p) => p.theme.responsive.xs} {
    padding: 0 2rem;
  }
`;
